document.addEventListener("DOMContentLoaded", function () {
    // Социальные иконки

    socialItem = document.querySelector(".fixed-social__main");
    socialItem.addEventListener("click", function () {
        this.classList.toggle("active");
    });

    // Класс active для активного пункта меню
    var activeUrl = window.location.pathname,
        menuItemHeader = document.querySelector(
            '.header-nav a[href="' + activeUrl + '"]'
        ),
        menuItemFooter = document.querySelector(
            '.footer-nav a[href="' + activeUrl + '"]'
        );
    menuItemMobile = document.querySelector(
        '.mobile-nav a[href="' + activeUrl + '"]'
    );
    if (menuItemHeader) {
        menuItemHeader.classList.add("active");
    }
    if (menuItemFooter) {
        menuItemFooter.classList.add("active");
    }
    if (menuItemMobile) {
        menuItemMobile.classList.add("active");
    }

    // Мобильное меню
    let menuBtn = document.querySelectorAll(".hamburger-menu");
    let mobileMenu = document.querySelector(".mobile-nav__wrap");
    for (let i = 0; i < menuBtn.length; i++) {
        menuBtn[i].addEventListener("click", function () {
            menuBtn[i].classList.toggle("open");
            mobileMenu.classList.toggle("active");
            document.querySelector("body").classList.toggle("body-block");
        });
    }

    // Плавный скролл по якорям
    document.querySelectorAll(".scroll-link").forEach((link) => {
        link.addEventListener("click", function (e) {
            e.preventDefault();

            let href = this.getAttribute("href").substring(1);

            const scrollTarget = document.getElementById(href);

            const topOffset = 100;
            // const topOffset = 0; // если не нужен отступ сверху
            const elementPosition = scrollTarget.getBoundingClientRect().top;
            const offsetPosition = elementPosition - topOffset;

            window.scrollBy({
                top: offsetPosition,
                behavior: "smooth",
            });
        });
    });

    // Слайдер на странице посёлка
    const swiperSingle = new Swiper("#single-slider", {
        slidesPerView: 1, // Слайдов на экран
        spaceBetween: 0, // Отступы между слайдами
        centeredSlides: true, // Центровка слайдов
        loop: true, // Бесконечная прокрутка
        lazy: true, // Ленивая загрузка
        speed: 400, // Скорость анимации
        parallax: true, // Параллакс эффект
        autoplay: {
            // Автоплей
            delay: 8000, // Время автоплея
            disableOnInteraction: false, // Отключение взаимодействия со слайдером при автоплее
            pauseOnMouseEnter: true, // Остановка автоплея при фокусе
        },
        navigation: {
            nextEl: ".slider-navigation__next",
            prevEl: ".slider-navigation__prev",
        },
        pagination: {
            el: ".slider-pagination",
            clickable: true,
        },
    });

    // Слайдер карточек посёлков в мобиле
    const swiperCards = new Swiper("#cards-slider", {
        slidesPerView: 1, // Слайдов на экран
        spaceBetween: 30, // Отступы между слайдами
        centeredSlides: true, // Центровка слайдов
        loop: true, // Бесконечная прокрутка
        lazy: true, // Ленивая загрузка
        speed: 700, // Скорость анимации
        parallax: true, // Параллакс эффект
        autoplay: {
            // Автоплей
            delay: 8000, // Время автоплея
            disableOnInteraction: false, // Отключение взаимодействия со слайдером при автоплее
            pauseOnMouseEnter: true, // Остановка автоплея при фокусе
        },
        navigation: {
            nextEl: ".cards-mobile__navigation_next",
            prevEl: ".cards-mobile__navigation_prev",
        },
        pagination: {
            el: ".cards-mobile__pagination",
            type: "fraction",
        },
    });

    // Слайдер карточек деятельности в мобиле
    const swiperActivity = new Swiper("#activity-slider", {
        slidesPerView: 1, // Слайдов на экран
        spaceBetween: 30, // Отступы между слайдами
        centeredSlides: true, // Центровка слайдов
        loop: true, // Бесконечная прокрутка
        lazy: true, // Ленивая загрузка
        speed: 700, // Скорость анимации
        parallax: true, // Параллакс эффект
        // autoplay: {
        //     // Автоплей
        //     delay: 8000, // Время автоплея
        //     disableOnInteraction: false, // Отключение взаимодействия со слайдером при автоплее
        //     pauseOnMouseEnter: true, // Остановка автоплея при фокусе
        // },
        navigation: {
            nextEl: ".activity-mobile__navigation_next",
            prevEl: ".activity-mobile__navigation_prev",
        },
        pagination: {
            el: ".activity-mobile__pagination",
            type: "fraction",
        },
    });

    // Маска телефона
    [].forEach.call(document.querySelectorAll(".input-tel"), function (input) {
        var keyCode;
        function mask(event) {
            event.keyCode && (keyCode = event.keyCode);
            var pos = this.selectionStart;
            if (pos < 3) event.preventDefault();
            var matrix = "+7 (___) ___-__-__",
                i = 0,
                def = matrix.replace(/\D/g, ""),
                val = this.value.replace(/\D/g, ""),
                new_value = matrix.replace(/[_\d]/g, function (a) {
                    return i < val.length
                        ? val.charAt(i++) || def.charAt(i)
                        : a;
                });
            i = new_value.indexOf("_");
            if (i != -1) {
                i < 5 && (i = 3);
                new_value = new_value.slice(0, i);
            }
            var reg = matrix
                .substr(0, this.value.length)
                .replace(/_+/g, function (a) {
                    return "\\d{1," + a.length + "}";
                })
                .replace(/[+()]/g, "\\$&");
            reg = new RegExp("^" + reg + "$");
            if (
                !reg.test(this.value) ||
                this.value.length < 5 ||
                (keyCode > 47 && keyCode < 58)
            )
                this.value = new_value;
            if (event.type == "blur" && this.value.length < 5) this.value = "";
        }

        input.addEventListener("input", mask, false);
        input.addEventListener("focus", mask, false);
        input.addEventListener("blur", mask, false);
        input.addEventListener("keydown", mask, false);
    });
});
